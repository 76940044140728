
import { Component, Vue } from "vue-property-decorator";
import { Action } from "@/models/utils/table-action.interface";
import { DataTable } from "@/models/utils/datatable.interface";
import { CellType, Header } from "@/models/utils/header.interface";
import { User } from "@/models/entities/user.interface";
import { userService } from "@/services/api/users.service";
import UserForm from "./UserForm.vue";
import { getModule } from "vuex-module-decorators";
import store from "@/store";
import OverlayModule from "@/store/modules/overlay.module";
import SnackbarModule from "@/store/modules/snackbar.module";
const overlayModule = getModule(OverlayModule, store);
const snackbarModule = getModule(SnackbarModule, store);

@Component({ components: { UserForm } })
export default class Users extends Vue {
  private dialogDelete = false;
  private userToDelete: number = null;
  private onDeleteMessage = "";
  private userDialog = {
    show: false,
    title: "",
    user: {} as User,
    isEdit: false,
  };
  private changePasswordTextProp = "password";
  private isPasswordModifiedProp = false;
  private newPasswordProp = "";
  private newPasswordCheckProp = "";
  private selectedRoleProp = null;
  private valid = false;
  private search = "";

  private showDeleted = false;

  private headers: Header[] = [
    {
      text: "ID",
      align: "start",
      filterable: true,
      value: "id",
      cellType: CellType.DEFAULT,
      isPrimary: false,
      class: "llDataTable_header",
    },
    {
      text: "Email",
      align: "start",
      filterable: true,
      value: "email",
      cellType: CellType.DEFAULT,
      isPrimary: true,
      class: "llDataTable_header black--text",
    },
    {
      text: "Creato da",
      align: "start",
      filterable: true,
      value: "createdBy",
      cellType: CellType.DEFAULT,
      isPrimary: false,
      class: "llDataTable_header",
    },
    {
      text: "Data creazione",
      align: "start",
      filterable: true,
      value: "createdOn",
      cellType: CellType.DATE,
      isPrimary: false,
      class: "llDataTable_header",
    },
    {
      text: "Stato",
      align: "start",
      filterable: false,
      value: "isDeleted",
      cellType: CellType.STATUS,
      isPrimary: false,
      class: "llDataTable_header",
      customProp: "activeUser",
    },
    {
      text: "Azioni",
      align: "start",
      filterable: false,
      sortable: false,
      value: "actions",
      cellType: CellType.ACTION,
      isPrimary: false,
      class: "llDataTable_header",
      customProp: "isDeleted",
    },
  ];

  public items: DataTable<unknown> = {
    key: "id",
    loading: true,
    headers: this.headers,
    values: [],
    search: "",
    actions: [
      { id: Action.EDIT, name: "edit" },
      { id: Action.USEROFF, name: "user-off" },
    ],
  };

  async created(): Promise<void> {
    await this.getTableItems();
  }

  private async getTableItems(): Promise<void> {
    overlayModule.showOverlay();
    const u = await userService.getAllUsers(this.showDeleted);
    this.$set(this.items, "values", u.data);
    this.items.loading = false;
  }

  private async reactivateUser(item: User): Promise<void> {
    overlayModule.showOverlay();
    await userService.reactivateUser(item.id);
  }

  private onSearch(value: string): void {
    this.$set(this.items, "search", value);
  }

  private async showUserDialog(item: User): Promise<void> {
    this.$set(this.userDialog, "show", true);
    if (item) {
      this.$set(this.userDialog, "title", "Modifica utente");
      overlayModule.showOverlay();
      const u = await userService.getUserByID(item.id);
      this.$set(this.userDialog, "user", u.data);
      this.$set(this.userDialog, "isEdit", true);
    } else {
      this.$set(this.userDialog, "title", "Nuovo utente");
      this.$set(this.userDialog, "user", {} as User);
      this.$set(this.userDialog, "isEdit", false);
    }
  }

  private closeUserDialog(): void {
    this.$set(this.userDialog, "show", false);
  }

  private async saveUser(): Promise<void> {
    const isValid = (
      this.$refs.form as Vue & { validate: () => boolean }
    ).validate();
    this.valid = isValid;
    if (this.valid) {
      if (this.userDialog.isEdit && this.userDialog.user.id) {
        this.$set(
          this.userDialog.user,
          "passwordChanged",
          this.isPasswordModifiedProp
        );
        this.$set(
          this.userDialog.user,
          "password",
          this.changePasswordTextProp
        );
        await userService.updateUser(
          this.userDialog.user.id,
          this.userDialog.user
        );
      } else {
        this.$set(
          this.userDialog.user,
          "passwordChanged",
          this.isPasswordModifiedProp
        );
        this.$set(this.userDialog.user, "password", this.newPasswordCheckProp);
        this.userDialog.user.roles = [this.selectedRoleProp];
        await userService.saveUser(this.userDialog.user);
      }
      await this.getTableItems();
      this.$set(this.userDialog, "show", false);
      snackbarModule.showSnackbar({
        message: "Salvataggio avvenuto con successo",
        type: "success",
      });
      setTimeout(() => snackbarModule.hideSnackbar(), 2000);
    }
  }

  private onDelete(item: User): void {
    this.userToDelete = item.id;
    this.onDeleteMessage =
      "Sei sicuro di volere disabilitare l'utente: " + item.email + " ?";
    this.dialogDelete = true;
  }

  private async deleteUser(): Promise<void> {
    overlayModule.showOverlay();
    await userService.deleteUser(this.userToDelete);
    this.getTableItems();
    this.dialogDelete = false;
    snackbarModule.showSnackbar({
      message: "Eliminazione avvenuta con successo",
      type: "success",
    });
    setTimeout(() => snackbarModule.hideSnackbar(), 2000);
  }

  private async onReactivateUser(item: User): Promise<void> {
    await this.reactivateUser(item);
    this.showDeleted = false;
    this.getTableItems();
  }

  private async onDeletePermanently(item: User): Promise<void> {
    overlayModule.showOverlay();
    await userService.deletePermanently(item.id);
    this.getTableItems();
  }
}
