
import { Component, Prop, PropSync, Vue } from "vue-property-decorator";
import { User } from "@/models/entities/user.interface";
import { Role } from "@/models/entities/role.interface";
import { Customer } from "@/models/entities/customer.interface";
import {
  required,
  passwordLength,
  passwordCheck,
  passwordIsPassword,
  email,
  checkEmailExistance,
} from "@/helpers/rules";
import { rolesService } from "@/services/api/roles.sevice";
import { customers } from "@/services/api/customers.service";
import OverlayModule from "@/store/modules/overlay.module";
import { CLIENT } from "@/helpers/roles";
import { getModule } from "vuex-module-decorators";
import store from "@/store";
import AuthModule from "@/store/modules/auth.module";
import { authenticate } from "@/services/api/authenticate.service";
const overlayModule = getModule(OverlayModule, store);
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const authModule = getModule(AuthModule, store);

@Component
export default class UserForm extends Vue {
  /** Sets the form value */
  @Prop({ required: true }) private user: User;
  /** Sets if the form is edit */
  @Prop({ required: true }) private isEdit: boolean;
  @PropSync("changePasswordTextProp") private changePasswordText: string;
  @PropSync("isPasswordModifiedProp") private isPasswordModified: boolean;
  @PropSync("newPasswordProp") private newPassword: string;
  @PropSync("newPasswordCheckProp") private newPasswordCheck: string;
  @PropSync("selectedRoleProp") private selectedRole: string;

  private required = required;
  private passwordLength = passwordLength;
  private passwordCheck = passwordCheck;
  private passwordIsPassword = passwordIsPassword;
  private email = email;
  private checkEmailExistance = checkEmailExistance;

  private roles = [] as Role[];
  private customers = [] as Customer[];
  private emailNotUsed = true;
  private emailCheck = "";

  get clientRoleId(): number {
    if (this.roles && this.roles.length) {
      let returnedRole = this.roles.find((role) => role.roleName == CLIENT);
      return returnedRole.id;
    }
    return null;
  }

  get selectClientRules(): unknown[] {
    if (parseInt(this.selectedRole) == this.clientRoleId) {
      return [required];
    } else {
      return [];
    }
  }

  get rolesWithoutClient(): Role[] {
    return this.roles.filter((role) => role.roleName != CLIENT);
  }

  get clients(): unknown[] {
    let clients = [] as unknown[];
    if (this.customers) {
      this.customers.forEach((customer) => {
        if (!customer.firstName) {
          clients.push({ id: customer.id, name: customer.businessName });
        } else if (!customer.businessName) {
          clients.push({ id: customer.id, name: customer.fullName });
        }
      });
      clients;
      return clients;
    }
    return [];
  }

  async mounted(): Promise<void> {
    await Promise.all([this.getAllRoles(), this.getAllCustomers()]);
    this.$emit("validate");
    this.emailCheck = this.user.email;
  }

  private async getAllRoles(): Promise<void> {
    overlayModule.showOverlay();
    const r = await rolesService.getAllRoles();
    this.roles = r.data;
  }

  private async getAllCustomers(): Promise<void> {
    overlayModule.showOverlay();
    const c = await customers.getAllCustomersByCustomerTypeID();
    this.customers = c.data;
  }

  private checkRole(id: number): boolean {
    const r = this.roles.find((role) => role.id == id);
    if (r && r.roleName == CLIENT) return false;
    return true;
  }

  private passwordChanged(): void {
    if (this.changePasswordText == null) {
      this.changePasswordText = "password";
    }
    switch (this.changePasswordText) {
      case "":
        this.isPasswordModified = false;
        this.changePasswordText = "password";
        break;
      case "password":
        this.isPasswordModified = false;
        this.changePasswordText = null;
        break;
      default:
        this.isPasswordModified = true;
        break;
    }
  }

  private async checkEmailDispatch(): Promise<void> {
    if (this.emailCheck != this.user.email) {
      const r = await authenticate.checkEmail(this.user.email);
      this.emailNotUsed = r.data;
    }
  }
}
