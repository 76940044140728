import { Role } from "@/models/entities/role.interface";
import { ApiService } from "../api.service";

const CONTROLLER = "role";

class RolesService extends ApiService {
  getAllRoles() {
    return this.getAll<Role[]>(CONTROLLER);
  }
}

export const rolesService = new RolesService();
